const relatedSlider = (selector, options) => {
    document.querySelectorAll(selector).forEach(el => {
        if (!el.swiper) {
            // eslint-disable-next-line no-undef
            el.swiper = new Swiper(el, options)
        }
    })
}

export default relatedSlider

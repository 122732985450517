import './../../scss/blocks/_related-news.scss'

import oneLineMobileSlider from '../components/oneLineMobileSlider'

document.addEventListener('DOMContentLoaded', () => {
    const options = {
        loop: false,
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',
        initialSlide: 0,
    }

    oneLineMobileSlider('.related-news__slider', '193.125rem', options)
})
